@import 'mastodon/mixins';

// Commonly used web colors
$black: #000000;            // Black
$white: #ffffff;            // White
$success-green: #79bd9a !default;    // Padua
$error-red: #df405a !default;        // Cerise
$warning-red: #ff5050 !default;      // Sunset Orange
$gold-star: #ca8f04 !default;        // Dark Goldenrod

$red-bookmark: $warning-red;

// Values from the classic Mastodon UI
$classic-base-color: #282c37;         // Midnight Express
$classic-primary-color: #9baec8;      // Echo Blue
$classic-secondary-color: #d9e1e8;    // Pattens Blue
$classic-highlight-color: #1c89d1;    // Chaotic Blue

// fixes for 4.2.0 part 1 
$ui-button-background-color: $classic-highlight-color;
$ui-button-focus-background-color: darken($classic-highlight-color, 4%) !default;
// end fixes

// Variables for defaults in UI
$base-shadow-color: $black !default;
$base-overlay-background: $black !default;
$base-border-color: $white !default;
$simple-background-color: $white !default;
$valid-value-color: $success-green !default;
$error-value-color: $error-red !default;

// Tell UI to use selected colors
$ui-base-color: $classic-base-color !default;                  // Darkest
$ui-base-lighter-color: lighten($ui-base-color, 26%) !default; // Lighter darkest
$ui-primary-color: $classic-primary-color !default;            // Lighter
$ui-secondary-color: $classic-secondary-color !default;        // Lightest
$ui-highlight-color: $classic-highlight-color !default;

// Variables for texts
$primary-text-color: $white !default;
$darker-text-color: $ui-primary-color !default;
$dark-text-color: $ui-base-lighter-color !default;
$secondary-text-color: $ui-secondary-color !default;
$highlight-text-color: lighten($ui-highlight-color, 8%) !default;
$action-button-color: $ui-base-lighter-color !default;
$passive-text-color: $gold-star !default;
$active-passive-text-color: $success-green !default;

// For texts on inverted backgrounds
$inverted-text-color: $ui-base-color !default;
$lighter-text-color: $ui-base-lighter-color !default;
$light-text-color: $ui-primary-color !default;

// Language codes that uses CJK fonts
$cjk-langs: ja, ko, zh-CN, zh-HK, zh-TW;

// Variables for components
$media-modal-media-max-width: 100%;

// put margins on top and bottom of image to avoid the screen covered by image.
$media-modal-media-max-height: 80%;

$no-gap-breakpoint: 1175px;

$font-sans-serif: 'mastodon-font-sans-serif' !default;
$font-display: 'mastodon-font-display' !default;
$font-monospace: 'mastodon-font-monospace' !default;

@import 'mastodon/mixins';
@import 'mastodon/variables';
@import 'fonts/roboto';
@import 'fonts/roboto-mono';

@import 'mastodon/reset';
@import 'mastodon/basics';
@import 'mastodon/branding';
@import 'mastodon/containers';
@import 'mastodon/lists';
@import 'mastodon/widgets';
@import 'mastodon/forms';
@import 'mastodon/accounts';
@import 'mastodon/components';
@import 'mastodon/polls';
@import 'mastodon/modal';
@import 'mastodon/emoji_picker';
@import 'mastodon/about';
@import 'mastodon/tables';
@import 'mastodon/admin';
@import 'mastodon/dashboard';
@import 'mastodon/rtl';
@import 'mastodon/accessibility';
@import 'mastodon/rich_text';

@import 'mascot';

@media screen and (min-width: 1025px) {
  .layout-multiple-columns .column,
  .layout-multiple-columns .drawer {
    flex: 1 1 auto;
    min-width: 300px;
    max-width: 400px;
  }
}

/* more contrast an right color for links in toots */
.status__content a,
.status__content a.unhandled-link,
.hashtag-bar a,
.column-header__back-button {
  color: #6bc1ff;
}

.reply-indicator__content p, .status__content p {
  line-height: 21px;
  margin-bottom: 16px;
}

.server-banner__meta__column .account .account__display-name {
  gap: 6px;
}

.navigation-panel__logo {
  display: none;
}

.about__section:nth-of-type(4), .about__section:nth-of-type(5) {
  display: none;
}

.audio-player__canvas:not([title]),
.audio-player__canvas[title=""],
.media-gallery__gifv video:not([title]),
.media-gallery__gifv video[title=""],
.media-gallery__item-thumbnail img:not([alt]),
.media-gallery__item-thumbnail img[alt=""],
.video-player video:not([title]),
.video-player video[title=""] {
      border-bottom: 4px solid #ff4253;
        box-sizing: border-box;
}

/* make link preview status card smaller*/

.status-card {
    border-radius: 4px;
    &__actions {
        & > div {
            border-radius: 4px;
        }
    }
}
.status-card__title {
    font-size: 16px;
    line-height: 18px;
}
.status-card__content {
    padding: 8px 12px;
}
.status-card__image {
    flex: 0 0 auto;
}
.status-card__image,
.status-card__image-image,
.status-card__image-preview {
    border-start-start-radius: 4px;
}
.status-card.expanded .status-card__image,
.status-card.expanded .status-card__image-image,
.status-card.expanded .status-card__image-preview {
    border-start-end-radius: 4px;
}

/*Fix background colors of elements*/
.column > .scrollable {
	background: #282c37;
}

.column .column-header {
	background: #282c37;
}

.column .notification__filter-bar {
	background: #282c37;
}

.column-subheading {
    background-color: #393F4F;
}

.drawer__header{
   background-color: #393F4F;
}

.drawer__inner{
   background-color: #393F4F;
}

.search__input {
    background: #282c37;
}

/* Make Getting started footer more readable*/
.link-footer p {
    color:#9baec8;
}

.link-footer p a {
    color:#9baec8;
}

/* Make borders slightly more visible than our default colors */
:root {
    --background-border-color: #393f4f;
}


/*Change color and add more contrast to icon buttons*/
.icon-button {
    color: #9baec8;
}

.icon-button:hover, .icon-button:focus {
    color: #d7dfe9
}

/* Don't show follow button in notifications */
.notification-group__actions > .button {
    display: none;
}

/* Remove warning bars from content warnings */
.content-warning {
    border-width: 0px 5px 0px 5px;
    border-radius: 4px;
    border-color: #9baec8;
    border-style: solid
}
.content-warning:after,
.content-warning:before {
    content: unset;
}

/* Make filters and content warnings distinguishable*/
.content-warning--filter {
    border-color: #ff4253;
}

/* Fix compose form */
.compose-form .icon-button {
    color: #9baec8;
}

.compose-form .dropdown-button {
    color: #9baec8;
}

.compose-form .spoiler-input__border{
    display: none;
}

.compose-form .spoiler-input .autosuggest-input{
    border-width: 0px 5px 0px 5px;
    border-color: #9baec8;
    border-style: solid
}

.compose-form .autosuggest-textarea__textarea, .compose-form .spoiler-input__input {
    color:#ebeff4;
}

 /* fix header for simple view */
#tabs-bar__portal {
    background: #282c37;
}

/* new fixes */

.dropdown-button {
    border: 1px solid #9baec8;
}

content-warning:after,
.content-warning:before {
  content: unset;
}

.content-warning {
    p {
        margin-top: 1.5em;
	margin-bottom: 0px;
    }
    button.link-button:hover {
        text-decoration: none;
    }
    button.link-button {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        span {
            position: absolute;
            top: 8px;
            left: 0;
            text-indent: 13px;
            color: #9baec8;
            visibility: hidden;
            height: 20px;
            width: 100%;
            overflow: hidden;
	    text-align: left;
            &:before {
                content: "Show post with CN:";
                display: block;
                visibility: visible;
            }
        }
    }
}

.content-warning--filter {
    button.link-button span {
        &:before {
            content: "Show filtered post:";
            display: block;
            visibility: visible;
        }
    }
}

.content-warning:has(+ .status__content, notification-group__embedded-status__content) {
    button.link-button span {
        &:before {
            content: "Hide post with CN:";
            display: block;
            visibility: visible;
        }
    }
}

.content-warning:has(+ .notification-group__embedded-status__content) {
    button.link-button span {
        &:before {
            content: "Hide post with CN:";
            display: block;
            visibility: visible;
        }
    }
}

.content-warning--filter:has(+ .status__content, notification-group__embedded-status__content) {
    button.link-button span {
        &:before {
            content: "Hide filtered post:";
            display: block;
            visibility: visible;
        }
    }
}

.content-warning--filter:has(+ .notification-group__embedded-status__content) {
    button.link-button span {
        &:before {
            content: "Hide filtered post:";
            display: block;
            visibility: visible;
        }
    }
}
